import styled from "styled-components";
import {HeadTitle, Paragraph} from "../../../App/Styles/Typography.styled";
import {sizes} from "../../../Utils/screenUtil";

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    max-width: 600px;
    border-bottom: 1px solid rgba(199, 199, 199, 0.3);
    border-right: 1px solid rgba(199, 199, 199, 0.3);
    border-top: none;
    background-color: rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: ${sizes.tablet}) {
        /* Styles for screens less than tablets */
        width: 500px;
    }
    @media only screen and (max-width: 575px) {
        /* Styles for screens less than 575 pixels */
        width: 400px;
    }
    @media only screen and (max-width: ${sizes.mobileL}) {
        /* Styles for screens less than 425px */
        width: 360px;
    }
    @media only screen and (max-width: 376px) {
        /* Styles for screens less than 376px */
        width: 345px;
    }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    gap: 1.5rem;
`;

export const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 1.5rem;

    width: 100%;
`;

export const SubmitButton = styled.button<{ isLoading: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    background-color: #ad8449;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    opacity: ${({isLoading}) => (isLoading ? 0.7 : 1)};
    pointer-events: ${({isLoading}) => (isLoading ? 'none' : 'auto')};
    white-space: nowrap;
    font-size: 1em;
    transition: 0.3s opacity ease-in-out;

    &:hover {
        opacity: 0.8;
    }
`;

export const HiddenButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
`;

export const ButtonText = styled(Paragraph)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000000;
    font-size: 1.5em;
    font-weight: bold;
`;

export const Footnote = styled.p`
    font-size: 1em;
`;

export const StyledHeadTitle = styled(HeadTitle)`
    margin-bottom: 1.5rem;
    font-size: 28px;
    align-self: flex-start;
    color: #f4f4f4;
    font-weight: 700;
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 20px;
    align-self: flex-start;
    color: #f4f4f4;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Checkbox = styled.input`
    margin-right: 0.5rem;
    accent-color: #ad8449;
`;

export const CheckboxLabel = styled.label`
    font-size: 1em;
    color: #c7c7c7;
`;

export const ForgotPassword = styled.a`
    font-size: 1em;
    text-decoration: none;
    color: #ad8449;
`;

const SignUpContainer = styled.div`
    margin-top: 1.5rem;
    text-align: center;
`;

const SignUpText = styled(Paragraph)`
    font-size: 1em;
    color: #c7c7c7;
`;

const SignUpLink = styled.a`
    text-decoration: none;
    color: #ad8449;
    font-weight: 900;
`;

const InputLabel = styled.label`
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #c7c7c7;
`;

export const MainContainer = styled.div`
  padding-left: 7%;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than tablets */
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
`;


export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
`;


export const StyledInput = styled.input`
  font-size: 1.2em; /* Make the input text larger */
  font-family: inherit;
  padding: 0.5em 0.5em;
  margin-top: 1rem;
  width: 100%;
  background-color: transparent;
  color: #c7c7c7;
  border: 1px solid rgba(199, 199, 199, 0.6); /* Updated to use RGBA */
  border-radius: 10px;
  height: 45px;
  outline: none; /* Remove default outline */

  &:focus {
    border-color: #c7c7c7;
    box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
  }
`;