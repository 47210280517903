import React, { createContext, useState } from 'react';
import useChatApi from '../../Hooks/useApi/useChatApi';

export interface UserSettings {
  origin_state: string;
  origin_city: string;
  equipment_type: string;
  companies: string[];
}
interface SettingsContextType {
  settings: UserSettings;
  settingsIsLoading: boolean;
  updateSettings: (updatedSettings: UserSettings) => Promise<void>;
  fetchSettingsError: string | null;
  fetchSettings: () => Promise<void>;
}

export const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

// Default values for UserSettings
const defaultSettings: UserSettings = {
  origin_state: '',
  origin_city: '',
  equipment_type: '',
  companies: [],
};

// Provider component
export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState<UserSettings>(defaultSettings);
  const [settingsIsLoading, setSettingsIsLoading] = useState<boolean>(false);
  const [fetchSettingsError, setFetchSettingsError] = useState<string | null>(
    null
  );
  const { fetchChatSettings, updateChatSettings } = useChatApi();

  // Fetch settings function
  const fetchSettings = async () => {
    setSettingsIsLoading(true);
    const fetchedSettings = await fetchChatSettings(setFetchSettingsError); // Pass error setter to API function
    if (fetchedSettings) {
      setSettings(fetchedSettings);
    }
    setSettingsIsLoading(false);
  };

  // Update settings function
  const updateSettings = async (updatedSettings: UserSettings) => {
    setSettingsIsLoading(true); // Start loading when updating settings
    try {
      await updateChatSettings(updatedSettings); // Make the patch request
      setSettings(updatedSettings); // Update the local state with the new settings
    } catch (error) {
      console.error('Error updating user settings:', error);
    } finally {
      setSettingsIsLoading(false); // Stop loading when the request is complete
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        settingsIsLoading,
        updateSettings,
        fetchSettingsError,
        fetchSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
