import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    ButtonText,
    CardContainer,
    CardContent, ErrorMessageContainer, FormContainer, HiddenButton,
    MainContainer,
    PageContainer,
    StyledHeadTitle,
    StyledParagraph, SubmitButton
} from "./ForgotPasswordPage.styled";
import HelmetView from "../../../Components/Helmet/HelmetView";
import NavigationLoginLayout from "../../../Layouts/Navigation/NavigationLogInLayout";
import {Popup} from "../../../Components/Popup/Popup";
import {VectorImage} from "../../../App/Styles/Image.styled";
import closeSvg from "../../../Assets/close.svg";
import {Paragraph} from "../../../App/Styles/Typography.styled";
import AuthInputField from "../../../Components/Input/AuthInputField";
import BottombarLayout from "../../../Layouts/Bottombar/BottombarLayout";
import useForgotPasswordApi from "../../../Hooks/useApi/useForgotPasswordApi";
import {IResetPassword} from "../../../Models/UserModel";

const SetNewPasswordPage = () => {
    const {uidb64, token} = useParams();
    const {setNewPasswordApi} = useForgotPasswordApi();
    const navigate = useNavigate();

    // states
    const [password, setPassword] = useState<string | null>(null);
    const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        console.log(`${uidb64} ||| ${token}`);
    }, []);

    const handleSetNewPassword = async () => {
        if (isLoading) return;
        if (!password) {
            setErrorMessage("Password field is empty");
            return;
        }
        if (confirmPassword !== password) {
            setErrorMessage("Wrong confirm password");
            return;
        }
        const payload = {
            uidb64: uidb64,
            token: token,
            password: password
        } as IResetPassword
        setIsLoading(true);
        const message = await setNewPasswordApi(payload);
        setIsLoading(false);
        if (message) setErrorMessage(message);
        else {
            setSuccessMessage("Your password has been successfully reset");
        }
    }

    const handlePopupExit = () => {
        setSuccessMessage("");
        navigate("/auth");
    }

    return (
        <PageContainer>
            <HelmetView title="Set New Password - Tarmac-AI"/>
            <NavigationLoginLayout/>
            <MainContainer>
                <CardContainer>
                    <CardContent>
                        <StyledHeadTitle bold={true}>Create new password</StyledHeadTitle>
                        <StyledParagraph>
                            Enter your new password to complete the reset.
                        </StyledParagraph>
                        {successMessage && <Popup title="Success!" subtitle={successMessage}
                                                  handleExit={() => handlePopupExit()}/>}
                        {errorMessage && (
                            <ErrorMessageContainer>
                                <HiddenButton onClick={() => setErrorMessage("")}>
                                    <VectorImage src={closeSvg} height="100%"/>
                                </HiddenButton>
                                <div>
                                    <Paragraph>{errorMessage}</Paragraph>
                                </div>
                            </ErrorMessageContainer>
                        )}
                        <FormContainer>
                            <AuthInputField title={"Password"} inputType={"password"}
                                            onTextChange={(value) => setPassword(value)}/>
                            <AuthInputField title={"Confirm password"} inputType={"password"}
                                            onTextChange={(value) => setConfirmPassword(value)}/>
                        </FormContainer>
                        <SubmitButton isLoading={isLoading} onClick={() => handleSetNewPassword()}>
                            <ButtonText>Reset</ButtonText>
                        </SubmitButton>
                    </CardContent>
                </CardContainer>
            </MainContainer>
            <BottombarLayout/>
        </PageContainer>
    );
};

export default SetNewPasswordPage;