import { IScheduleItem, Status } from '../Models/SchedulerModel';

export const sortPrompts = (prompts: IScheduleItem[]) => {
  const statusOrder: { [key in Status]: number } = {
    [Status.Active]: 1,
    [Status.Completed]: 2,
    [Status.Inactive]: 3,
    [Status.Expired]: 4,
  };

  return prompts.sort((a, b) => {
    // Compare status first
    const statusComparison =
      statusOrder[a.status as Status] - statusOrder[b.status as Status];
    if (statusComparison !== 0) return statusComparison;

    // If statuses are the same, sort by creation date (most recent first)
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);

    return bDate.getTime() - aDate.getTime();
  });
};
