import React from 'react';
import { AuthenticationContextProvider } from '../Auth/AuthContext';
import { ChildrenProps } from '../index';
import { ThemeContextProvider } from '../Theme/ThemeContext';
import { LoadingContextProvider } from '../Loading/LoadingContext';
import { PluginContextProvider } from '../Plugin/PluginContext';
import { PortalContextProvider } from '../Portal/PortalContext';
import { SettingsProvider } from '../Settings/SettingsContext';

const GlobalContext: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <ThemeContextProvider>
      <SettingsProvider>
        <PortalContextProvider>
          <LoadingContextProvider>
            <AuthenticationContextProvider>
              <PluginContextProvider>{children}</PluginContextProvider>
            </AuthenticationContextProvider>
          </LoadingContextProvider>
        </PortalContextProvider>
      </SettingsProvider>
    </ThemeContextProvider>
  );
};

export default GlobalContext;
