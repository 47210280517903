import React, {useState} from 'react';
import {
    ButtonText,
    CardContainer,
    CardContent,
    ErrorMessageContainer,
    FormContainer,
    HiddenButton,
    MainContainer,
    PageContainer,
    StyledHeadTitle,
    StyledParagraph,
    SubmitButton
} from "./ForgotPasswordPage.styled";
import NavigationLoginLayout from "../../../Layouts/Navigation/NavigationLogInLayout";
import HelmetView from "../../../Components/Helmet/HelmetView";
import AuthInputField from "../../../Components/Input/AuthInputField";
import BottombarLayout from "../../../Layouts/Bottombar/BottombarLayout";
import useForgotPasswordApi from "../../../Hooks/useApi/useForgotPasswordApi";
import {Popup} from "../../../Components/Popup/Popup";
import {VectorImage} from "../../../App/Styles/Image.styled";
import closeSvg from "../../../Assets/close.svg";
import {Paragraph} from "../../../App/Styles/Typography.styled";


const ForgotPasswordPage = () => {
    const {resetRequestPasswordApi} = useForgotPasswordApi();
    // states
    const [email, setEmail] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");


    const handleForgotPassword = async () => {
        if (isLoading) return;
        if (email) {
            setErrorMessage("");
            setIsLoading(true);
            const message = await resetRequestPasswordApi(email);
            setIsLoading(false);
            if (message) setErrorMessage(message);
            else {
                setSuccessMessage("A link to reset your password has been sent to your email. Please check your inbox and follow the instructions.");
            }
        }
    };

    return (
        <PageContainer>
            <HelmetView title="Forgot Password - Tarmac-AI"/>
            <NavigationLoginLayout/>
            <MainContainer>
                <CardContainer>
                    <CardContent>
                        <StyledHeadTitle bold={true}>Forgot Password?</StyledHeadTitle>
                        <StyledParagraph>
                            No worries, we'll send you reset instructions.
                        </StyledParagraph>
                        {successMessage && <Popup title="Success!" subtitle={successMessage}
                                                  handleExit={() => setSuccessMessage("")}/>}
                        {errorMessage && (
                            <ErrorMessageContainer>
                                <HiddenButton onClick={() => setErrorMessage("")}>
                                    <VectorImage src={closeSvg} height="100%"/>
                                </HiddenButton>
                                <div>
                                    <Paragraph>{errorMessage}</Paragraph>
                                </div>
                            </ErrorMessageContainer>
                        )}
                        <FormContainer>
                            <AuthInputField title={"Email"} inputType={"email"}
                                            onTextChange={(value) => setEmail(value)}/>
                        </FormContainer>
                        <SubmitButton isLoading={isLoading} onClick={() => handleForgotPassword()}>
                            <ButtonText>Submit</ButtonText>
                        </SubmitButton>
                    </CardContent>
                </CardContainer>
            </MainContainer>
            <BottombarLayout/>
        </PageContainer>
    );
};

export default ForgotPasswordPage;