import { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ThemeContext } from '../Context/Theme/ThemeContext';
import Router from '../Routes/Router';
import GlobalStyle from './Styles/Global.styled';
import { setupThemeProvider } from './Themes/themes';
import { PortalContext } from '../Context/Portal/PortalContext';
import PortalLayout from '../Layouts/Portal/PortalLayout';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const portalContext = useContext(PortalContext);
  const themeContext = useContext(ThemeContext);
  const theme = themeContext?.theme;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={setupThemeProvider(theme!)}>
        <Container>
          <GlobalStyle />
          <Router />
        </Container>
        {portalContext?.isOpen && (
          <PortalLayout onClose={() => portalContext?.setIsOpen(false)}>
            {portalContext?.modal}
          </PortalLayout>
        )}
        <Toaster
          position="top-center"
          reverseOrder={true}
          toastOptions={{
            success: {
              duration: 3000,
            },
            error: { duration: 3000 },
            style: {
              fontSize: '16px',
              maxWidth: '500px',
              padding: '16px 24px',
              background: '',
              color: 'var(--color-grey-700)',
            },
          }}
        />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.primary};
`;
