import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { VectorImage as OriginalVectorImage } from '../../App/Styles/Image.styled';
import {
  HeadTitle,
  Paragraph as OriginalParagraph,
} from '../../App/Styles/Typography.styled';
import { HiddenButton } from '../../App/Styles/Button.styled';
import profileSvg from '../../Assets/profile.svg';
import logoSvg from '../../Assets/logo.svg';
import { AuthenticationContext } from '../../Context/Auth/AuthContext';
import { sizes } from '../../Utils/screenUtil';

const NavigationLoginLayout = () => {
  const authContext = useContext(AuthenticationContext);

  return (
    <Container>
      <LogoContainer to="/">
        <VectorImage src={logoSvg} white />
        <HeadTitleNavigation bold={true}>TarmacAI</HeadTitleNavigation>
      </LogoContainer>
      <CompanyLinkContainer
        href="https://tarmac-ai.com"
        gridColumn={2}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paragraph>COMPANY</Paragraph>
      </CompanyLinkContainer>
      {/* <LinkContainer */}
      {/*     href="https://docs.google.com/forms/d/e/1FAIpQLSfK1O1lH9EDGrCZlCkyMaJ4CmzZBnF6onIcAhSEyQvr80IGLg/viewform" */}
      {/*     gridColumn={3} target="_blank" rel="noopener noreferrer" */}
      {/* > */}
      <ContactLinkContainer href="/contact" gridColumn={3}>
        <Paragraph>CONTACT</Paragraph>
      </ContactLinkContainer>
      <LinkContainer
        href="/help"
        gridColumn={4}
      >
        <Paragraph>HELP</Paragraph>
      </LinkContainer>
      <ProfileContainer>
        {authContext?.tokens && (
          <HiddenButton padding="1rem" onClick={authContext.logoutListener}>
            <Paragraph>Logout</Paragraph>
          </HiddenButton>
        )}
        <HiddenButton>
          <VectorImage src={profileSvg} height="30px" width="30px" white />
        </HiddenButton>
      </ProfileContainer>
    </Container>
  );
};

export default NavigationLoginLayout;

const VectorImage = styled(OriginalVectorImage)<{ white?: boolean }>`
  ${({ white }) =>
    white &&
    `
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(106%) contrast(106%);
    `}
`;

const Paragraph = styled(OriginalParagraph)`
  font-size: 24px;
  font-weight: 500;
  color: #c7c7c7;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 1320px pixels */
    font-size: 1.4rem;
  }
`;

const HeadTitleNavigation = styled(HeadTitle)`
  color: #ad8449;
`;

const Container = styled.div`
  grid-area: nav;
  display: grid;
  gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  height: 4rem;
  background-color: transparent;
  @media only screen and (max-width: ${sizes.laptop}) {
    /* Styles for screens less than 1024px */
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  grid-column: 1;

  ${VectorImage} {
    filter: ${({ theme }) => theme.activeFilter};
  }
`;

const LinkContainer = styled.a<{ gridColumn: number | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: ${(props) => (props?.gridColumn ? props.gridColumn : 1)};

  &:hover {
    opacity: 0.7;
  }
`;

const CompanyLinkContainer = styled(LinkContainer)`
  @media only screen and (max-width: ${sizes.laptop}) {
    /* Styles for screens less than 1024px */
    display: none;
  }
`;

const ContactLinkContainer = styled(LinkContainer)`
  @media only screen and (max-width: 520px) {
    display: none;
  }
`;

const ProfileContainer = styled.div`
  color: #f4f4f4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  margin-right: 2rem;
  gap: 1.5rem;
  grid-column: 6;
`;
