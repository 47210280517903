import React, { useContext } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { AuthenticationContext } from '../Context/Auth/AuthContext';

type Props = {
  component: React.ComponentType;
};

const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const authContext = useContext(AuthenticationContext);
  const tokens = authContext?.tokens;
  const [searchParams] = useSearchParams();
  const scheduledPrompt = searchParams.get('scheduled_prompt');

  // Before any routing and redirects, if there is a scheduled Prompt, add to session storage
  if (scheduledPrompt) {
    sessionStorage.setItem('scheduled_prompt', scheduledPrompt);
  }
  return tokens ? <RouteComponent /> : <Navigate to={'/auth'} />;
};

export default PrivateRoute;
