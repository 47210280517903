import useAxios from '../useAxios/useAxios';
import {
  GET_ALL_PROMPTS,
  DEACTIVATE_PROMPT,
  CREATE_PROMPT,
} from '../../App/Axios/endpoints';

import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { IScheduleItem } from '../../Models/SchedulerModel';
import { useState } from 'react';

const useSchedulerApi = () => {
  const authAxios = useAxios(true);

  const fetchPrompts = async (
    status?: string,
    page: number = 1,
    size: number = 50
  ) => {
    try {
      const url = `${GET_ALL_PROMPTS}?page=${page}&size=${size}${
        status ? `&status=${status}` : ''
      }`;
      const response = await authAxios.get(url);
      if (response.status === 200) {
        return response.data.items as IScheduleItem[];
      }
      return [];
    } catch (error) {
      console.error('Error fetching prompts:', error);
      toast.error('Error fetching prompts');
      return [];
    }
  };

  const deactivatePrompt = async (promptID: number) => {
    const url = `${DEACTIVATE_PROMPT}/${promptID}`;
    try {
      const response = await authAxios.patch(url, promptID);

      if (response.status === 200) {
        console.log('Prompt deactivation succesful', response.data);
        return response.data; // Return the updated data
      }
    } catch (error) {
      console.error('Error deactivating prompt:', error);
      toast.error('Error deactivating prompt');
      throw error;
    }
  };

  const createPrompt = async (promptText: string, boardNames: string[]) => {
    try {
      const response = await authAxios.post(CREATE_PROMPT, {
        prompt_text: promptText,
        board_names: boardNames,
      });
      if (response.status === 200) {
        console.log('Prompt creationg succesful', response.data);
        return response.data; // Return the updated data
      }
    } catch (error) {
      console.error('Error creating prompt:', error);
      toast.error('Error creating prompt');
      throw error;
    }
  };
  return {
    fetchPrompts,
    deactivatePrompt,
    createPrompt,
  };
};

export const usePagination = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(100);

  return { page, size, setPage, setSize };
};

export const useGetPrompts = (page: number, size: number, status?: string) => {
  const { fetchPrompts } = useSchedulerApi();
  const {
    data: promptsResponse,
    isLoading: promptsIsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['all Prompts', page, size],
    queryFn: ({ pageParam = 1 }) => fetchPrompts(status, pageParam, size),
    onError: (err: Error) => {
      toast.error(err.message || 'Failed getting prompts');
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage && lastPage.length < size) return undefined; // Returns no more pages
      return allPages.length + 1; // Return the next page number
    },
  });

  const promptsResponseFlattened = promptsResponse?.pages.flat() || [];

  return {
    promptsResponseFlattened,
    promptsIsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export const useDeactivatePrompt = () => {
  const queryClient = useQueryClient();
  const { page, size } = usePagination();
  const { deactivatePrompt } = useSchedulerApi();
  const { mutate: deactivatePromptFn, isLoading: deactivateIsLoading } =
    useMutation({
      mutationFn: (promptID: number) => deactivatePrompt(promptID),
      onSuccess: () => {
        toast.success('Prompt deactivated and list updated!');
        queryClient.invalidateQueries(['all Prompts', page, size]);
      },
      onError: (err: Error) => {
        toast.error(err.message || 'Failed deactivating prompt');
      },
    });

  return { deactivatePromptFn, deactivateIsLoading };
};

export const useCreatePrompt = () => {
  const queryClient = useQueryClient();
  const { page, size } = usePagination();
  const { createPrompt } = useSchedulerApi();
  const { mutate: createPromptFn, isLoading: createPromptIsLoading } =
    useMutation({
      mutationFn: ({
        promptText,
        boardNames,
      }: {
        promptText: string;
        boardNames: string[];
      }) => createPrompt(promptText, boardNames),
      onSuccess: () => {
        toast.success('Post Creation Successful');
        queryClient.invalidateQueries(['all Prompts', page, size]);
      },
      onError: (err: Error) => {
        console.error(err.message || 'Failed deactivating prompt');
      },
    });

  return { createPromptFn, createPromptIsLoading };
};

export default useSchedulerApi;
