import {
  KeyboardEvent,
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  GreetingTextContainer,
  HelpBoxList,
} from './ChatPage.styled';
import MessageRecycler, {
  MessageItem,
} from '../../../Components/Recycler/MessageRecycler';
import useChatApi from '../../../Hooks/useApi/useChatApi';
import { Title } from '../../../App/Styles/Typography.styled';
import { GREETING_TEXT, HELP_TEXT_LIST } from '../../../Other/constants';
import { MultipleMessagesData } from '../../../Models/BaseModel';
import HelpBox from '../../../Components/HelpBox/HelpBox';
import { getRandomElements } from '../../../Utils/collectionUtils';
import { HelpBoxType } from '../../../App/Types/helpBox';
import { AuthenticationContext } from '../../../Context/Auth/AuthContext';
import { PluginContext } from '../../../Context/Plugin/PluginContext';
import { ChatPageInput } from './ChatPageInput';
import { useSettings } from '../../../Context/Settings/useSettings';
import { Alert } from '@mui/material';

const ChatPage = () => {
  const { sendMessage } = useChatApi();
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState<MessageItem[]>([]);
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const [helpList] = useState<HelpBoxType[]>(
    getRandomElements(HELP_TEXT_LIST, 4)
  );
  const [responseCompleted, setResponseCompleted] = useState<boolean>(false);
  const { fetchSettings } = useSettings();
  const authContext = useContext(AuthenticationContext);
  const [scheduledPromptMissingProviders, setScheduledPromptMissingProviders] =
    useState<string>('');
  const [hasFetchedScheduledPrompt, setHasFetchedScheduledPrompt] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const scheduledPromptSession = sessionStorage.getItem('scheduled_prompt');

  // fetch for Settings as soon as ChatPage mounts
  useEffect(() => {
    searchParams.delete('scheduled_prompt');
    setSearchParams(searchParams);
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const pluginContext = useContext(PluginContext);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await onSendMessageClickListener();
    }
  };

  const onSendMessageClickListener = useCallback(
    async (query?: string) => {
      const textQuery = query ? query : inputText;
      const boards = [];

      if (isProgress) return;
      if (query === '' && inputText === '') return;
      if (inputRef.current !== null) inputRef.current!.value = '';

      // Redirect to auth if not found
      const tokens = authContext?.tokens;
      if (tokens === null) {
        console.log(tokens);
        return navigate('/auth');
      }

      setMessages((prevState) => [
        ...prevState,
        { isUser: true, title: textQuery } as MessageItem,
      ]);

      // Notify user no providers are enabled
      if (!pluginContext!.LB123Plugin && !pluginContext!.truckstopPlugin) {
        console.error('User not authorized with any boards');
        setMessages((prevState) => [
          ...prevState,
          {
            isUser: false,
            title:
              'You are not authorized with any providers. Please log in to a provider to get results.',
          } as MessageItem,
        ]);
        setResponseCompleted(true); // Set response completed
        return;
      }

      if (pluginContext!.LB123Plugin) boards.push('123LoadBoard');
      if (pluginContext!.truckstopPlugin) boards.push('truckstop');

      setIsProgress(true);
      setInputText('');
      const response = await sendMessage(textQuery, boards);

      if (response.status === 200 || response.status === 201) {
        const multipleMessagesData = response!.data as MultipleMessagesData;
        // Summary of resultsArray: Array with multiple results, each result object is treated by the MessageRecycler.tsx
        // as a single message prompt or message. Regardless on whether the sendMessage response has 1 or multiple results.
        const resultsArray = multipleMessagesData.data.results;
        resultsArray.forEach((result) => {
          const dynamicText = result.relaxed_message
            ? result.relaxed_message
            : result.text;

          // FOR SINGLE RESULTS WITHOUT LOADS
          if (result.loads.length === 0) {
            if (result.text.startsWith('Unfortunately')) {
              setMessages((prevState) => [
                ...prevState,
                {
                  isUser: false,
                  title: dynamicText,
                  userPrompt: result.user_query.trim(),
                  promptTruckType: false,
                  overallPromptNum: resultsArray.length,
                } as MessageItem,
              ]);
            }
            if (
              result.text.startsWith('There were no results for your query.')
            ) {
              setMessages((prevState) => [
                ...prevState,
                {
                  isUser: false,
                  title: dynamicText,
                  userPrompt: result.user_query.trim(),
                  promptTruckType: false,
                  overallPromptNum: resultsArray.length,
                } as MessageItem,
              ]);
            }
            if (result.text.startsWith('Please enter the equipment type')) {
              setMessages((prevState) => [
                ...prevState,
                {
                  isUser: false,
                  title: dynamicText,
                  userPrompt: result.user_query.trim(),
                  promptTruckType: resultsArray.length > 1 ? false : true, // logic for helpboxes based on # of results
                  overallPromptNum: resultsArray.length,
                } as MessageItem,
              ]);
            }
            // SINGLE current result, just with loads present to form a table
          } else if (result.loads.length > 0) {
            setMessages((prevState) => [
              ...prevState,
              {
                isUser: false,
                title: dynamicText,
                userPrompt: result.user_query.trim(),
                loadList: result.loads,
                overallPromptNum: resultsArray.length,
                isRelaxed: !!result.relaxed_message,
              } as MessageItem,
            ]);
          }
        });
      } else if (response.status === 400 || response.status === 404) {
        // Handle 400 errors
        setMessages((prevState) => [
          ...prevState,
          {
            isUser: false,
            title: response!.data.data.detail,
            promptTruckType:
              /\bequipment\b\s+.*?\btype\b|\btype\b\s+.*?\bequipment\b/gi.test(
                response!.data.data.detail
              ),
          } as MessageItem,
        ]);
      } else {
        // Something unexpectedly went wrong
        setMessages((prevState) => [
          ...prevState,
          {
            isUser: false,
            title: 'Something went wrong processing your query.',
          } as MessageItem,
        ]);
        if (pluginContext!.truckstopPlugin)
          pluginContext!.setTruckstopPlugin(false);
        navigate('/error'); // Redirect to error page
      }
      setIsProgress(false);
      setResponseCompleted(true);
    },
    [inputText, isProgress, authContext, navigate, pluginContext, sendMessage]
  );

  const onHelpBoxClickListener = async (query: string) => {
    if (inputRef.current !== null) {
      inputRef.current.value = query;
    }
    setInputText(() => query);
    await onSendMessageClickListener(query);
  };

  // Handle selection of a trucktype from the prompt
  const handleTruckTypeSelect = useCallback(
    (truckType: string) => {
      if (inputRef.current !== null) {
        const text = `Required equipment type is ${truckType}`;
        inputRef.current.value = text;
        setInputText(text);
        inputRef.current.focus(); // Focus on input after a selection
      }
    },
    [setInputText]
  );

  useEffect(() => {
    if (responseCompleted && inputRef.current) {
      inputRef.current.focus(); // Focus on the input element after response is completed
      setResponseCompleted(false); // Reset the response completed state
    }
  }, [responseCompleted]);

  const isConnectedToProviders =
    pluginContext?.truckstopPlugin || pluginContext!.LB123Plugin;

  useEffect(() => {
    // Ensure that the effect only runs when the truckstopPlugin becomes enabled
    if (
      scheduledPromptSession &&
      isConnectedToProviders &&
      !hasFetchedScheduledPrompt
    ) {
      onSendMessageClickListener(scheduledPromptSession);
      setHasFetchedScheduledPrompt(true); // to avoid looping the api call
      // Remove the scheduled_prompt from the session
      sessionStorage.removeItem('scheduled_prompt');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasFetchedScheduledPrompt,
    isConnectedToProviders,
    scheduledPromptSession,
  ]);

  useEffect(() => {
    if (isConnectedToProviders === false && scheduledPromptSession) {
      const timeout = setTimeout(() => {
        setScheduledPromptMissingProviders(
          'Connect to a provider for scheduled prompts'
        );
      }, 800);
      return () => clearTimeout(timeout);
    } else {
      setScheduledPromptMissingProviders('');
    }
  }, [isConnectedToProviders, scheduledPromptSession]);

  return (
    <Container>
      {!!scheduledPromptMissingProviders && (
        <Alert
          sx={{
            position: 'absolute',
            top: '-70px',
            marginTop: '35px',
            background: 'transparent',
            color: '#9b9b9b',
          }}
          severity="warning"
          onClose={() => setScheduledPromptMissingProviders('')}
        >
          {scheduledPromptMissingProviders}
        </Alert>
      )}

      {messages.length !== 0 ? (
        <MessageRecycler
          messages={messages}
          handleSelect={handleTruckTypeSelect}
          sendNewMessage={onSendMessageClickListener}
        />
      ) : (
        <>
          <GreetingTextContainer>
            <Title data-testid="greeting-text">{GREETING_TEXT}</Title>
          </GreetingTextContainer>
          <HelpBoxList>
            {helpList.map((item, index) => (
              <HelpBox
                key={index}
                item={item}
                onClick={() => onHelpBoxClickListener(item.title)}
              />
            ))}
          </HelpBoxList>
        </>
      )}
      <ChatPageInput
        inputRef={inputRef}
        handleKeyDown={handleKeyDown}
        inputText={inputText}
        setInputText={setInputText}
        isProgress={isProgress}
        onSendMessageClickListener={onSendMessageClickListener}
      />
    </Container>
  );
};

export default ChatPage;
