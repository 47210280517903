import { DefaultTheme } from 'styled-components';

export const getStatusColor = (
  theme: DefaultTheme,
  {
    isActive,
    isCompleted,
    isExpired,
  }: { isActive: boolean; isCompleted: boolean; isExpired: boolean }
) => {
  if (isCompleted) return theme.schedulerCompletedStatusText;
  if (isActive) return theme.schedulerActiveStatusText;
  if (isExpired) return theme.schedulerExpiredStatusText;
  return theme.schedulerInactiveStatusText;
};
