import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import useForgotPasswordApi from "../../../Hooks/useApi/useForgotPasswordApi";
import {
    ButtonText,
    CardContainer,
    CardContent,
    ErrorMessageContainer,
    MainContainer,
    PageContainer,
    StyledHeadTitle,
    StyledParagraph,
    SubmitButton
} from "./ForgotPasswordPage.styled";
import HelmetView from "../../../Components/Helmet/HelmetView";
import NavigationLoginLayout from "../../../Layouts/Navigation/NavigationLogInLayout";
import {Paragraph} from "../../../App/Styles/Typography.styled";
import BottombarLayout from "../../../Layouts/Bottombar/BottombarLayout";

interface RouteParams {
    uidb64: string;       // This will capture 'Mg'
    token: string;    // This will capture 'cemorb-1c31256551aeefb1f7d02a4630f001d0'
}


const RedirectResetPasswordPage: React.FC = () => {
    const {uidb64, token} = useParams();
    const {resetCheckTokenApi} = useForgotPasswordApi();
    // states
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!uidb64 && !token) return;
            if (isLoading) return;
            setIsLoading(true);
            const message = await resetCheckTokenApi(uidb64!, token!);
            setIsLoading(false);
            console.log(`MESSAGE ${message}`)
            if (message) setErrorMessage(message);
            else navigate(`/auth/set-password/${uidb64}/${token}`);
        })();
    }, []);

    const handleBackToLogin = () => {
        navigate("/auth");
    }

    return (
        <PageContainer>
            <HelmetView title="Tarmac-AI"/>
            <NavigationLoginLayout/>
            <MainContainer>
                {errorMessage && (
                    <CardContainer>
                        <CardContent>
                            <StyledHeadTitle bold={true}>Something went wrong</StyledHeadTitle>
                            <StyledParagraph>
                                Please try again or let us know about the issue.
                            </StyledParagraph>
                            <ErrorMessageContainer>
                                <div>
                                    <Paragraph>{errorMessage}</Paragraph>
                                </div>
                            </ErrorMessageContainer>
                            <SubmitButton isLoading={isLoading} onClick={() => handleBackToLogin()}>
                                <ButtonText>Back to Login</ButtonText>
                            </SubmitButton>
                        </CardContent>
                    </CardContainer>
                )}
            </MainContainer>
            <BottombarLayout/>
        </PageContainer>
    );
};

export default RedirectResetPasswordPage;