import useAxios from "../useAxios/useAxios";
import {RESET_CHECK_ENDPOINT, RESET_PASSWORD_ENDPOINT, RESET_REQUEST_ENDPOINT} from "../../App/Axios/endpoints";
import {IError, IResponse} from "../../Models/BaseModel";
import {IResetPassword} from "../../Models/UserModel";
import {getErrorMessage} from "../../App/Axios/api";

const useForgotPasswordApi = () => {
    // axios hook
    const axios = useAxios();

    const resetRequestPasswordApi = async (email: string) => {
        try {
            const response = await axios.post(`${RESET_REQUEST_ENDPOINT}`, {email: email});
            console.log(response);
            if (response.status === 200 || response.status === 201) {
                return "";
            }
            return response.data.message;
        } catch (err: any) {
            console.log(err);
            return getErrorMessage(err);
        }
    }

    const resetCheckTokenApi = async (uidb64: string, token: string) => {
        try {
            const response = await axios.get(`${RESET_CHECK_ENDPOINT}/${uidb64}/${token}`);
            if (response.status === 200 || response.status === 201) {
                return "";
            }
            return response.data.message;
        } catch (err: any) {
            console.log(err!);
            return getErrorMessage(err);
        }
    }

    const setNewPasswordApi = async (payload: IResetPassword) => {
        try {
            const response = await axios.patch(`${RESET_PASSWORD_ENDPOINT}`, payload);
            if (response.status === 200 || response.status === 201) {
                return "";
            }
            return response.data.message;
        } catch (err: any) {
            console.log(err!);
            return getErrorMessage(err);
        }
    }

    return {resetRequestPasswordApi, resetCheckTokenApi, setNewPasswordApi}
}

export default useForgotPasswordApi;
