import styled from 'styled-components';
import { sizes } from '../../Utils/screenUtil';

interface UserDropdownProps {
  selectedPath: string;
  onSelect: (path: string) => void;
}

const UserDropdown: React.FC<UserDropdownProps> = ({
  selectedPath,
  onSelect,
}) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const path = event.target.value;
    onSelect(path);
  };

  return (
    <DropdownContainer>
      <DropdownSelect value={selectedPath} onChange={handleSelectChange}>
        <option value="">Identity</option>
        <option value="company">Company Details</option>
        <option value="provider">Provider Details</option>
      </DropdownSelect>
    </DropdownContainer>
  );
};

export default UserDropdown;

const DropdownContainer = styled.div`
  position: relative;
  display: none;

  @media only screen and (max-width: ${sizes.laptop}) {
    display: block;
  }
`;

const DropdownSelect = styled.select`
  background-color: ${({ theme }) => theme.detailsText};
  border: ${({ theme }) => theme.borderColor}
  outline: none !important;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  width: auto;
  font-size: 1rem;
  color: #333;
  outline: none;
  transition: border-color 0.3s, background-color 0.3s;

  /* Hover and focus styles */
  &:hover {
    background-color: gray;
  }

`;
