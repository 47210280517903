import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CustomSettingsModal } from './CustomSettingsModal';

export const SettingsModalWrapper = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen((prevIsOpenState) => !prevIsOpenState);
  };

  let iconFontSize = '30px';

  const matches2240px = useMediaQuery('(max-width:2240px)');
  const matches1800px = useMediaQuery('(max-width:1800px)');
  const matches1320px = useMediaQuery('(max-width:1320px)');

  if (matches2240px) {
    iconFontSize = '28px';
  }
  if (matches1800px) {
    iconFontSize = '22px';
  }
  if (matches1320px) {
    iconFontSize = '18px';
  }

  return (
    <SettingsFullContainer onClick={handleClick}>
      <StyledSettingsIcon sx={{ fontSize: iconFontSize }} />
      <span>Settings</span>
      <CustomSettingsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </SettingsFullContainer>
  );
};

const SettingsFullContainer = styled.div`
  color: ${(props) => props.theme.detailsText};
  font-size: 1rem;
  margin-left: 12px;
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  :hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px */
    font-weight: 600 !important;
    font-size: 1.6rem !important;
  }
  @media only screen and (max-width: 769px) {
    /* Styles for screens less than 769px */
    transform: translate(-8px, -5px);
  }
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${(props) => props.theme.detailsText};
  margin-right: 5px;
  transform: translateY(5px);
`;
