import { useContext } from 'react';
import { SettingsContext } from './SettingsContext';

// Custom hook for using SettingsContext
export const useSettings = () => {
  const settingsContext = useContext(SettingsContext);
  if (!settingsContext) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return settingsContext;
};
