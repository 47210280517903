import styled from 'styled-components';

interface InputGroupProps {
  label: string;
  name: string;
  type: string;
  value: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string; // Optional for input placeholder
  disabled?: boolean;
}

export const UserProfileInputGroup: React.FC<InputGroupProps> = ({
  label,
  name,
  type,
  value,
  handleInputChange,
  placeholder,
  disabled,
}) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder || ''}
        disabled={disabled}
      />
    </FormGroup>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.detailsText};
`;

const Input = styled.input`
  outline: none;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.settingsDisabledBg : 'lightgray'};
`;
