import styled from 'styled-components';
import { useEffect, useState } from 'react';

import UserProfileCard from '../../UserProfileCard/UserProfileCard';
import { UserProfileInputGroup } from './UserProfileInputGroup';
import { useGetUser } from '../../../Hooks/useApi/useAuthenticationApi';
import { IUser } from '../../../Models/UserModel';
import { defautUserValues } from './defaultUserValues';
import { CustomCircularLoading } from '../../CustomCircularLoading/CustomCircularLoading';

export const ProviderForm = () => {
  const { userData, userDataIsLoading, error } = useGetUser();
  const [{ ts_id }, setFormValues] = useState<IUser>(defautUserValues);

  // Sync state with userData once it arrives
  useEffect(() => {
    if (userData) {
      setFormValues(userData);
    }
  }, [userData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Call your update function here
  };

  if (userDataIsLoading) {
    return (
      <UserProfileCard>
        <CustomCircularLoading />;
      </UserProfileCard>
    );
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <UserProfileCard>
        {error && <ErrorSpan>Error fetching user profile data</ErrorSpan>}
        <UserProfileInputGroup
          label="Truckstop Integration ID"
          name="ts_id"
          type="text"
          value={ts_id || ''}
          handleInputChange={handleInputChange}
          disabled
        />
        <FormFooter>
          <Button type="submit" disabled>
            <ButtonSpan>Save</ButtonSpan>
          </Button>
        </FormFooter>
      </UserProfileCard>
    </FormContainer>
  );
};
const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const ErrorSpan = styled.span`
  font-size: 15px;
  color: red;
  margin-bottom: 20px;
`;

const ButtonSpan = styled.span`
  color: ${({ theme }) => theme.buttonText};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.button`
  width: 100px;
  border-radius: 10px;
  background-color: ${({ theme, disabled }) =>
    disabled ? `gray` : theme.title};
  padding: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? '1' : '.8')};
  }
`;
