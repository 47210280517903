import { ReactNode } from 'react';
import styled from 'styled-components';

interface UserProfileCardProps {
  children: ReactNode;
}

const UserProfileCard: React.FC<UserProfileCardProps> = ({ children }) => {
  return <UserProfileWrapper>{children}</UserProfileWrapper>;
};

export default UserProfileCard;

const UserProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.userProfileCardBorder};`}
  padding: 30px;
`;
