import axios, {AxiosInstance} from "axios";
import {isString} from "../../Utils/guardUtil";

export const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_URL, // Replace with your API base URL
    timeout: 5000, // Set a timeout for requests
});

export const authAxiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_URL, // Replace with your API base URL
    timeout: 5000, // Set a timeout for requests
});


export const getErrorMessage = (error: any): string => {
    let result = "Something went wrong";
    let detailMessage, message;
    if (error && !error.response) {
        return result;
    }
    const data = error!.response.data;
    try {
        detailMessage = data.data.detail;
    } catch {
        detailMessage = undefined;
    }
    try {
        message = data.message;
    } catch {
        message = undefined;
    }
    if (detailMessage && isString(detailMessage)) result = detailMessage;
    else if (message && isString(message)) result = message;
    return result;
}