import styled from 'styled-components';
import MuiModal from '@mui/material/Modal';
import { Close } from '@styled-icons/material';
import { SettingsForm } from '../../Form/Settings/SettingsForm';
import ThemeToggle from '../../Toogle/ThemeToggle';
import { Text, Title } from '../../../App/Styles/Typography.styled';
import { sizes } from '../../../Utils/screenUtil';

interface CustomSettingsModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomSettingsModal: React.FC<CustomSettingsModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <MuiModal open={isOpen} closeAfterTransition>
      <TransparentModal>
        <ContentContainer onClick={(e) => e.stopPropagation()}>
          <SettingsHeader>
            <SettingsTitle>Settings</SettingsTitle>
            <CloseIcon size={35} onClick={() => setIsOpen(false)} />
          </SettingsHeader>
          <SettingCell>
            <SettingCellTitleContainer>
              <SettingsCellTitle>Default Chat Settings</SettingsCellTitle>
            </SettingCellTitleContainer>
            <SettingsForm />
          </SettingCell>
          <SettingCell>
            <SettingCellTitleContainer>
              <SettingsCellTitle>Dark & Light Theme</SettingsCellTitle>
            </SettingCellTitleContainer>
            <ThemeToggle $mainPage={true} />
          </SettingCell>
        </ContentContainer>
      </TransparentModal>
    </MuiModal>
  );
};

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.alternativeWhite};
  position: absolute;
  right: 5px;
  cursor: pointer;
`;

const SettingsHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const SettingsTitle = styled(Title)`
  display: flex;
  color: ${({ theme }) => theme.title};
  font-size: 45px;
  margin-bottom: 15px;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    font-size: 40px;
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    font-size: 32px;
  }
`;

const SettingCellTitleContainer = styled.div`
  width: 100%;
`;

const SettingsCellTitle = styled(Text)`
  font-size: 20px;
  color: ${({ theme }) => theme.detailsText};
  margin: 10px 10px 10px 0;
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    font-size: 18px;
  }
`;

const TransparentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
`;

const ContentContainer = styled.div`
  width: 500px;
  min-height: 80%;
  border-radius: 15px;
  padding: 15px 5px 15px 5px;
  max-height: 80%; /* Set a max height relative to the viewport */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  background-color: ${(props) => props.theme.secondary};
  border: 1px solid ${(props) => props.theme.settingsBorderOutlineColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    width: 400px;
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    width: 300px;
  }
  @media only screen and (max-width: ${sizes.mobileM}) {
    /* Styles for screens less than 375px */
    width: 280x;
  }
  ::-webkit-scrollbar {
    width: 1em;
  }
  //   ::-webkit-scrollbar-button {
  //   background: #ccc
  // }
  // ::-webkit-scrollbar-track-piece {
  //     background: #888
  // }
  ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.hover};

    &:hover {
      cursor: pointer;
  }
  }​
`;

const SettingCell = styled.div<{ disabled?: boolean }>`
  border-top: 1px solid ${(props) => props.theme.settingsBorderColor};
  border-bottom: 1px solid ${(props) => props.theme.settingsBorderColor};
  padding: 20px 5px 20px 20px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &:last-child {
    border-bottom: none;
  }
`;
