import { darkPalette, lightPalette } from './colorPalette';
import { ThemeTypes } from '../../Context/Theme/ThemeContext';

declare module 'styled-components' {
  export interface DefaultTheme {
    primary: string;
    secondary: string;
    filterButtons: string;
    text: string;
    detailsText: string;
    alternateText: string;
    redBackground: string;
    sideMenu: string;
    sideMenuAccent: string;
    priceText: string;
    title: string;
    accent: string;
    hover: string;
    arrowIcon: string;
    arrowIconHovered: string;
    settingsBorderColor: string;
    settingsBorderOutlineColor: string;
    settingsDisabledBg: string;
    settingsEnabledBg: string;
    alternativeWhite: string;
    promptText: string;
    schedulerPromptText: string;
    schedulerIDText: string;
    schedulerStatusText: string;
    schedulerButtonBg: string;
    schedulerHoverBg: string;
    schedulerWhite: string;
    schedulerActiveStatusText: string;
    schedulerInactiveStatusText: string;
    schedulerCompletedStatusText: string;
    schedulerExpiredStatusText: string;
    profileButtonColor: string;
    profileButtonBackgroundColor: string;
    userProfileCardBorder: string;
    userProfileActiveBorder: string;
    buttonText: string;
    darkBackground: string;
    filter: string;
    primaryFilter: string;
    activeFilter: string;
    accentFilter: string;
    transition: string;
    shadowColor: string;
    borderColor: string;
  }
}

export const lightTheme = {
  primary: lightPalette.primary,
  secondary: lightPalette.secondary,
  filterButtons: lightPalette.filterButtons,
  text: lightPalette.text,
  detailsText: lightPalette.detailsText,
  alternateText: lightPalette.alternateText,
  redBackground: lightPalette.redBackground,
  sideMenu: lightPalette.sidemenu,
  sideMenuAccent: lightPalette.sideMenuAccent,
  priceText: lightPalette.priceText,
  title: lightPalette.title,
  accent: lightPalette.accent,
  hover: lightPalette.hover,
  arrowIcon: lightPalette.arrowIcon,
  arrowIconHovered: lightPalette.arrowIconHovered,
  settingsBorderColor: lightPalette.settingsBorderColor,
  settingsBorderOutlineColor: lightPalette.settingsBorderOutlineColor,
  settingsDisabledBg: lightPalette.settingsDisabledBg,
  settingsEnabledBg: lightPalette.settingsEnabledBg,
  alternativeWhite: lightPalette.alternativeWhite,
  promptText: lightPalette.promptText,
  schedulerPromptText: lightPalette.schedulerPromptText,
  schedulerIDText: lightPalette.schedulerIDText,
  schedulerStatusText: lightPalette.schedulerStatusText,
  schedulerButtonBg: lightPalette.schedulerButtonBg,
  schedulerHoverBg: lightPalette.schedulerHoverBg,
  schedulerWhite: lightPalette.schedulerWhite,
  schedulerActiveStatusText: lightPalette.schedulerActiveStatusText,
  schedulerInactiveStatusText: lightPalette.schedulerInactiveStatusText,
  schedulerCompletedStatusText: lightPalette.schedulerCompletedStatusText,
  schedulerExpiredStatusText: lightPalette.schedulerExpiredStatusText,
  profileButtonColor: lightPalette.profileButtonColor,
  profileButtonBackgroundColor: lightPalette.profileAvatarBackgroundColor,
  userProfileCardBorder: lightPalette.userProfileCardBorder,
  userProfileActiveBorder: lightPalette.userProfileActiveBorder,
  buttonText: lightPalette.buttonText,
  darkBackground: lightPalette.darkBackground,
  filter: lightPalette.filter,
  primaryFilter: lightPalette.primaryFilter,
  activeFilter: lightPalette.activeFilter,
  accentFilter: lightPalette.accentFilter,
  transition: lightPalette.transition,
  shadowColor: lightPalette.shadowColor,
  borderColor: lightPalette.borderColor,
};

export const darkTheme = {
  primary: darkPalette.primary,
  secondary: darkPalette.secondary,
  filterButtons: darkPalette.filterButtons,
  text: darkPalette.text,
  detailsText: darkPalette.detailsText,
  alternateText: darkPalette.alternateText,
  redBackground: darkPalette.redBackground,
  sideMenu: darkPalette.sidemenu,
  sideMenuAccent: darkPalette.sideMenuAccent,
  priceText: darkPalette.priceText,
  title: darkPalette.title,
  accent: darkPalette.accent,
  hover: darkPalette.hover,
  arrowIcon: darkPalette.arrowIcon,
  arrowIconHovered: darkPalette.arrowIconHovered,
  settingsBorderColor: darkPalette.settingsBorderColor,
  settingsBorderOutlineColor: darkPalette.settingsBorderOutlineColor,
  settingsDisabledBg: darkPalette.settingsDisabledBg,
  settingsEnabledBg: darkPalette.settingsEnabledBg,
  alternativeWhite: darkPalette.alternativeWhite,
  promptText: darkPalette.promptText,
  schedulerPromptText: darkPalette.schedulerPromptText,
  schedulerIDText: darkPalette.schedulerIDText,
  schedulerStatusText: darkPalette.schedulerStatusText,
  schedulerButtonBg: darkPalette.schedulerButtonBg,
  schedulerHoverBg: darkPalette.schedulerHoverBg,
  schedulerWhite: darkPalette.schedulerWhite,
  schedulerActiveStatusText: darkPalette.schedulerActiveStatusText,
  schedulerInactiveStatusText: darkPalette.schedulerInactiveStatusText,
  schedulerCompletedStatusText: darkPalette.schedulerCompletedStatusText,
  schedulerExpiredStatusText: darkPalette.schedulerExpiredStatusText,
  profileButtonColor: darkPalette.profileButtonColor,
  profileButtonBackgroundColor: darkPalette.profileAvatarBackgroundColor,
  userProfileCardBorder: darkPalette.userProfileCardBorder,
  userProfileActiveBorder: darkPalette.userProfileActiveBorder,
  buttonText: darkPalette.buttonText,
  darkBackground: darkPalette.darkBackground,
  filter: darkPalette.filter,
  primaryFilter: darkPalette.primaryFilter,
  activeFilter: darkPalette.activeFilter,
  accentFilter: darkPalette.accentFilter,
  transition: darkPalette.transition,
  shadowColor: darkPalette.shadowColor,
  borderColor: darkPalette.borderColor,
};

export const setupThemeProvider = (value: string) => {
  if (value === ThemeTypes.LIGHT_THEME) {
    return lightTheme;
  } else {
    return darkTheme;
  }
};
