import { Avatar } from '@mui/material';
import { useState, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { AuthenticationContext } from '../../Context/Auth/AuthContext';
import { TextSpan } from '../../App/Styles/Typography.styled';

export const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const authContext = useContext(AuthenticationContext);

  const handleAvatarClick = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <>
      <DropdownContainer>
        <Avatar
          onClick={handleAvatarClick}
          alt="User Avatar"
          src=""
          sx={{
            height: '30px',
            width: '30px',
            backgroundColor: theme.profileButtonBackgroundColor,
            color: theme.profileButtonColor,
            cursor: 'pointer',
            transition: theme.transition,
            '&:hover': {
              opacity: 0.4,
            },
          }}
        />
        {isOpen && (
          <>
            <Backdrop onClick={closeDropdown} />
            <DropdownContent>
              <CustomButton
                onClick={() => {
                  closeDropdown();
                  return navigate('/user');
                }}
              >
                <Span>User Profile</Span>
              </CustomButton>
              <CustomButton onClick={authContext?.logoutListener}>
                <Span>Logout</Span>
              </CustomButton>
            </DropdownContent>
          </>
        )}
      </DropdownContainer>
    </>
  );
};

// Styled components
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 80%;
  right: -10px;
  margin-top: 8px;
  padding: 10px;
  width: 150px;
  background-color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.detailsText}30;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  background: transparent;
  padding: 12px 16px;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  transition: background 0.2s, color 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.hover};
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
`;

const Span = styled(TextSpan)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.detailsText};
`;

export default ProfileDropdown;
