import useAxios from '../useAxios/useAxios';
import { LOGIN_ENDPOINT, USER_INFO_ENDPOINT } from '../../App/Axios/endpoints';
import { IUser } from '../../Models/UserModel';

import { useQuery } from 'react-query';

const useAuthenticationApi = () => {
  // axios hook
  const axios = useAxios();
  const authAxios = useAxios(true);

  // login to Django server
  const loginApi = async (email: string, password: string) => {
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      const response = await axios.post(LOGIN_ENDPOINT, formData);
      if (response.status === 200 || response.status === 201) {
        return response;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const getUser = async () => {
    try {
      const response = await authAxios.get(USER_INFO_ENDPOINT);
      if (response.status === 200 || response.status === 201) {
        return response.data.data as IUser;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  return { loginApi, getUser };
};

export default useAuthenticationApi;

export const useGetUser = () => {
  const { getUser } = useAuthenticationApi();

  const {
    data: userData,
    isLoading: userDataIsLoading,
    error,
  } = useQuery({
    queryKey: ['user data'],
    queryFn: getUser,
    onError: (err: Error) => {
      console.log(err.message || 'Failed getting prompts');
    },
  });

  return {
    userData,
    userDataIsLoading,
    error,
  };
};
