import React, {createContext, useState} from 'react';
import {ChildrenProps} from "../index";


interface IPortalContext {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    setModal: (children: JSX.Element) => void,
    modal: JSX.Element
}

const PortalContext = createContext<IPortalContext | null>(null);

const PortalContextProvider = ({children}: ChildrenProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modal, setModal] = useState<JSX.Element | null>(null);

    const contextData = {
        isOpen: isOpen,
        setIsOpen: setIsOpen,
        modal: modal,
        setModal: setModal
    } as IPortalContext;
    return (
        <PortalContext.Provider value={contextData}>
            {children}
        </PortalContext.Provider>
    );
};

export {PortalContextProvider, PortalContext};