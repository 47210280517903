import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthenticationContext } from '../Context/Auth/AuthContext';

type Props = {
  component: React.ComponentType;
};

const RedirectIfLoggedIn: React.FC<Props> = ({ component: RouteComponent }) => {
  const authContext = useContext(AuthenticationContext);
  const tokens = authContext?.tokens;

  // If the user is logged in, redirect to the main page
  return tokens ? <Navigate to="/" /> : <RouteComponent />;
};

export default RedirectIfLoggedIn;
