import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext, ThemeTypes } from '../../Context/Theme/ThemeContext';
import { Text as OriginalText } from '../../App/Styles/Typography.styled';
import Switch from '@mui/material/Switch';

interface ThemeToggleProps {
  $mainPage?: boolean;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ $mainPage }) => {
  const themeContext = useContext(ThemeContext);

  const isLightTheme = themeContext?.theme === ThemeTypes.LIGHT_THEME;

  const handleChange = () => {
    themeContext?.onChangeListener();
  };

  return (
    <Container>
      <Label>
        <SwitchTextLight active={isLightTheme} $mainPage={$mainPage}>
          Light
        </SwitchTextLight>
      </Label>

      <CustomSwitch
        checked={!isLightTheme}
        onChange={handleChange}
        name="themeToggle"
      />
      <SwitchTextDark active={!isLightTheme} $mainPage={$mainPage}>
        Dark
      </SwitchTextDark>
    </Container>
  );
};

export default ThemeToggle;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1px;
  border-radius: 20px;
  background-color: transparent;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

interface SwitchTextProps {
  active: boolean;
  $mainPage?: boolean;
}
const SwitchTextLight = styled(OriginalText)<SwitchTextProps>`
  color: ${({ active, $mainPage, theme }) =>
    $mainPage
      ? active
        ? theme.detailsText
        : theme.alternateText
      : active
      ? 'white'
      : theme.alternateText};
  opacity: 1;
  font-size: 1rem;
`;

const SwitchTextDark = styled(OriginalText)<SwitchTextProps>`
  color: ${({ active, $mainPage, theme }) =>
    $mainPage
      ? active
        ? theme.detailsText
        : theme.text
      : active
      ? theme.detailsText
      : theme.text};
  opacity: 1;
  font-size: 1rem;
`;

const CustomSwitch = styled(Switch)`
  & .MuiSwitch-switchBase {
    color: ${({ theme }) => theme.primary};
    &:hover {
      background-color: transparent; /* Remove hover background */
    }
  }
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.secondary};
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.secondary};
    border: 1px solid ${({ theme }) => theme.detailsText} !important;
  }
  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.text};
  }
  & .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.title};
  }
`;
