import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useChatApi from '../../../Hooks/useApi/useChatApi';
import { PluginContext } from '../../../Context/Plugin/PluginContext';

const LoginRedirectPage = () => {
  const pluginContext = useContext(PluginContext);
  const navigate = useNavigate();
  const { login123LB } = useChatApi();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    (async () => {
      try {
        const value = queryParams.get('code'); // Retrieve the value of a specific query parameter
        console.log(value);
        if (value === null) {
          console.log('value null');
          return;
        }
        const data = await login123LB(value);
        if (data === null) {
          console.log('data null');
          return;
        }
        pluginContext?.setLB123Plugin(true);
        navigate('/');
      } catch (e) {}
    })();
  }, [pluginContext, navigate, login123LB, location, queryParams]);

  return <></>;
};

export default LoginRedirectPage;
