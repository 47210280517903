import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paragraph } from '../../App/Styles/Typography.styled';
import NavigationLoginLayout from '../../Layouts/Navigation/NavigationLogInLayout';
import BottombarLayout from '../../Layouts/Bottombar/BottombarLayout';
import useRegisterApi from '../../Hooks/useApi/useRegisterApi';

const EmailVerifyPage = () => {
  const { token } = useParams();
  const { verifyEmail } = useRegisterApi();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    const handleVerify = async () => {
      if (!token) {
        return setError('Invalid token: undefined or no token');
      }
      const success = await verifyEmail(token);
      if (success) {
        return setTimeout(() => {
          navigate('/auth');
        }, 5000);
      }
      setError(
        'Something went wrong verifying your email, reload this page to try again or contact us.'
      );
    };

    handleVerify();
  }, [token, navigate, verifyEmail]);

  return (
    <PageContainer>
      <NavigationLoginLayout />
      <ContentContainer>
        {error ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          <StyledParagraph>
            Your email has been verified, navigating you to the home page soon.
          </StyledParagraph>
        )}
      </ContentContainer>
      <BottombarLayout />
    </PageContainer>
  );
};

export default EmailVerifyPage;

const PageContainer = styled.div`
  height: 100vh;
`;

const ErrorText = styled(Paragraph)`
  font-size: 2rem;
  color: red;
  opacity: 0.5;
`;

const ContentContainer = styled.div`
    width: fit-content;
    height: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-center;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 26px;
  margin: 20px 0;
`;
