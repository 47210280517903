import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const ContentLayout = () => {
  return (
    <Container>
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </Container>
  );
};

export default ContentLayout;

const Container = styled.div`
  grid-area: main;
  height: auto;
  display: flex;
  justify-content: center; /* Center the content horizontally */
`;

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  min-height: 100%;
  max-width: 1100px; // Set max colum incase the pc screen width is too big
  @media only screen and (max-width: 1850px) {
    /* Styles for screens less than 1850px pixels */
    max-width: 1000px;
  }
  @media only screen and (max-width: 1600px) {
    /* Styles for screens less than 1600px pixels */
    max-width: 900px;
  }
  @media only screen and (max-width: 1400px) {
    /* Styles for screens less than 1400px pixels */
    max-width: 800px;
  }
  @media only screen and (max-width: 1200px) {
    /* Styles for screens less than 1200px pixels */
    max-width: 700px;
  }
`;
