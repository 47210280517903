import styled from 'styled-components';
import {
  Outlet as ProfileOutlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { sizes } from '../../Utils/screenUtil';
import { H1Title } from '../../App/Styles/Typography.styled';
import UserSideBar from './UserSideBar';
import UserDropdown from '../../Components/UserDropdown/UserDropdown';

const UserPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname.split('/').pop(); // Get the last part of the URL

  const handleSidebarClick = (path: string) => {
    navigate(`/user/${path}`);
  };

  return (
    <UserContainer>
      <UserPageWrapper>
        <UserPageHeader>
          <UserPageTitle>User Profile</UserPageTitle>
          <UserDropdown
            selectedPath={currentPath || 'Identity'}
            onSelect={handleSidebarClick}
          />
        </UserPageHeader>
        <UserPageBody>
          <UserSideBar />
          <OutletWrapper>
            <ProfileOutlet />
          </OutletWrapper>
        </UserPageBody>
      </UserPageWrapper>
    </UserContainer>
  );
};

export default UserPage;

const OutletWrapper = styled.div`
  width: 100%;
  margin-left: 30px;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    margin-left: 5px;
  }
`;

const UserContainer = styled.div`
  display: flex;
  height: 100%;
  margin-top: 2rem;
  width: 100%;
  max-height: 80vh;
  // border: 3px solid blue;
  font-size: 1.1rem;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    margin-top: 4rem;
  }
`;

const UserPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-y: auto;
  width: 100%;
`;

const UserPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    margin: 0; /* Remove default margins for the h1 to prevent extra spacing */
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    margin-top: 0px;
  }
`;

const UserPageBody = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  overflow-y: auto; /* Allow scrolling if content overflows */
  width: 100%;
  // border: 3px dashed yellow;
`;

const UserPageTitle = styled(H1Title)`
  font-size: 80px;
  @media only screen and (max-width: ${sizes.desktopS}) {
    /* Styles for screens less than 1920px */
    font-size: 50px;
  }
  @media only screen and (max-width: ${sizes.laptopMid}) {
    /* Styles for screens less than 1250px */
    font-size: 40px;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    font-size: 35px;
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    font-size: 30px;
  }
`;
