import { FC } from 'react';
import styled from 'styled-components';

interface StyledIFrameProps {
  src: string,
  title?: string | undefined,
  width?: string | undefined,
}

const StyledIFrame: FC<StyledIFrameProps> = ({ src, title, width }) => {
  return (
    <Container width={width}>
      <IFrame
        src={src}
        frameBorder="0"
        allowFullScreen
        title={title || ""}
      ></IFrame>
    </Container>
  );
};

const Container = styled.div<{width: string | undefined}>`
  position: relative;
  padding-bottom: 50%;
  height: 0;
  overflow: hidden;
  border-radius: 20px;
  width: ${({width}) => width ? width : '100%'};
  margin: 2rem auto;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default StyledIFrame;
