import styled from 'styled-components';
import { useSettings } from '../../../Context/Settings/useSettings';

interface InputGroupProps {
  label: string;
  name: string;
  type: string;
  value: string;
  options?: string[]; // For the select dropdown, this is optional
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  placeholder?: string; // Optional for input placeholder
}

export const SettingsInputGroup: React.FC<InputGroupProps> = ({
  label,
  name,
  type,
  value,
  options,
  handleInputChange,
  placeholder,
}) => {
  const { fetchSettingsError } = useSettings();
  const isOnlyTruckType = name === 'equipment_type';
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      {type === 'select' ? (
        <Select
          name={name}
          id={name}
          value={value}
          onChange={handleInputChange}
          disabled={!!fetchSettingsError || !isOnlyTruckType}
        >
          <option value="">Select {label}</option>
          {options?.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          disabled={!!fetchSettingsError || !isOnlyTruckType}
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder || ''}
        />
      )}
    </FormGroup>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.detailsText};
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.settingsDisabledBg : theme.settingsEnabledBg};
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`;

const Input = styled.input<{ disabled: boolean }>`
  outline: none;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.settingsDisabledBg : 'lightgray'};
`;
