import React from "react";
import styled from "styled-components";

interface InputContainerProps {
    title: string;
    inputType: string;
    onTextChange: (value: string | null) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}


const AuthInputField: React.FC<InputContainerProps> = ({
                                                           title,
                                                           inputType,
                                                           onTextChange,
                                                           onKeyDown,
                                                       }) => (
    <div>
        <InputLabel>{title}</InputLabel>
        <StyledInput
            type={inputType}
            onChange={(e) => onTextChange(e.target.value)}
            onKeyDown={onKeyDown}
        />
    </div>
);

export default AuthInputField;


const InputLabel = styled.label`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #c7c7c7;
`;

const StyledInput = styled.input`
    font-size: 1.2em; /* Make the input text larger */
    font-family: inherit;
    padding: 0.5em 0.5em;
    margin-top: 1rem;
    width: 100%;
    background-color: transparent;
    color: #c7c7c7;
    border: 1px solid rgba(199, 199, 199, 0.6); /* Updated to use RGBA */
    border-radius: 10px;
    height: 45px;
    outline: none; /* Remove default outline */

    &:focus {
        border-color: #c7c7c7;
        box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
    }
`;
